import React, { FC } from "react";
import ListLike from "../../listLike/listLike";
import { PowerfulWrapper } from "./powerful.styles";
import toolsPowerful from "../../../images/tools/tools-bg3.svg";
import check from "../../../images/check.svg";

interface Props {
  //   title: string;
}
const Powerful: FC<Props> = () => {
  const listData = [
    {
      subTitle:
        "Create custom rules based on any customer, device or transaction attribute",
      icon: check,
    },
    {
      subTitle:
        "Rules execute in 100’s of milliseconds, so you can take actions on rules in near real-time.",
      icon: check,
    },
    {
      subTitle:
        "Back-test rules in “shadow mode” before deploying to production, to eliminate false positives.",
      icon: check,
    },
  ];
  return (
    <section className="section-wrapper  relative" css={PowerfulWrapper}>
      <div className="curves-parent absolute top-full left-full"></div>
      <div className="relative wrapper-parent layout-width w-full pt-28">
        <div className="flex flex-row justify-between h-full md:flex-col">
          <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col justify-center mb-32 md:mb-0">
              <p className="title-parent font-Inter text-blue-darken text-5xl tracking-tight font-semibold mb-10 md:text-3xl md:text-left md:mb-7 md:mr-auto">
                Powerful no-code rule builder
              </p>
              <div className="list-parent flex flex-col justify-center w-full mb-6">
                {listData.map((data, i) => (
                  <ListLike key={i} subTitle={data.subTitle} icon={data.icon} />
                ))}
              </div>
            </div>
          </div>
          <div className="image-parent flex flex-col justify-start mx-auto lg:justify-center md:mb-11">
            <img src={toolsPowerful} alt="powerful no-code" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Powerful;
