import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo/seo";
import CustomerDegree from "../components/toolsSections/customerDegree/customerDegree";
import EasyUse from "../components/toolsSections/easyUse/easyUse";
import Powerful from "../components/toolsSections/powerful/powerful";
import BlueSection from "../components/blueSetion/blueSection";

const ToolPage = () => {
  const textArr = ["Move money", " fast, ", "without risk."];
  return (
    <Layout>
      <Seo title="Tool" pathname="/tool" />
      <main>
        <CustomerDegree />
        <EasyUse />
        <Powerful />
        <BlueSection textArr={textArr} btnText={"Book a demo"} />
      </main>
    </Layout>
  );
};

export default ToolPage;
