import React, { FC } from "react";
import ListLike from "../../listLike/listLike";
import { EasyUseWrapper } from "./easyUse.styles";
import toolsEasy from "../../../images/tools/tools-bg2.svg";
import check from "../../../images/check.svg";

interface Props {
  //   title: string;
}
const EasyUse: FC<Props> = () => {
  const listData = [
    {
      subTitle:
        "Granular ability to block current and future users by specific attribute such as phone number, email address, or device IP.",
      icon: check,
    },
    {
      subTitle:
        "Supports case batch review and assignment. Queue up users and transactions for post-hoc analysis.",
      icon: check,
    },
    {
      subTitle:
        "Easily approve or block users, and return funds for all pending transactions to originating bank account.",
      icon: check,
    },
  ];
  return (
    <section className="section-wrapper  relative" css={EasyUseWrapper}>
      <div className="curves-parent absolute top-full left-full"></div>
      <div className="relative wrapper-parent layout-width w-full pt-28">
        <div className="easy-parent flex flex-row justify-between h-full md:flex-col">
          <div className="image-parent flex flex-col justify-start mx-auto lg:justify-center md:mb-11">
            <img src={toolsEasy} alt="Easy use" />
          </div>
          <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col justify-center mb-32 md:mb-0">
              <p className="title-parent font-Inter text-blue-darken tracking-tight text-5xl font-semibold mb-10 md:text-3xl md:text-left md:mb-7 md:mr-auto">
                Easy to use case management
              </p>
              <div className="list-parent flex flex-col justify-center w-full mb-6">
                {listData.map((data, i) => (
                  <ListLike key={i} subTitle={data.subTitle} icon={data.icon} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EasyUse;
