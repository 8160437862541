import { css } from "@emotion/react";
import styled from "@emotion/styled";
import overlay from "../../images/overlay_grouped.svg";
import media from "../breakpoints";

const BlueWrapper = styled.section`
  .overlay {
    background: url(${overlay}) no-repeat;
    background-position: bottom right;
  }
`;

const Text = css`
  font-size: 44px;
  ${media("md")} {
    font-size: 28px;
  }
`;
const TextSmall = css`
  font-size: 28px;
  ${media("md")} {
    font-size: 24px;
  }
`;
const Parent = css`
  min-height: 148px;
`;

export { BlueWrapper, Text, Parent, TextSmall };
