import React, { FC } from "react";
import { BlueWrapper, Parent } from "./blueSection.styles";
import { navigate } from "gatsby-link";

interface Props {
  textArr: string[];
  btnText: string;
  size?: "sm" | "md";
}
const BluSection: FC<Props> = ({ textArr, btnText, size = "md" }) => {
  return (
    <BlueWrapper className="section-wrapper pb-24 px-8 md:px-4 relative md:pb-12">
      <div
        className="relative layout-width bg-white flex flex-row justify-center py-13 mx-8 rounded-3xl overflow-hidden md:flex-col md:px-4 md:mx-4 md:mb-6"
        css={Parent}
      >
        <div className="overlay w-full h-full top-0 left-0 absolute" />
        <p className="blue-main md:mt-14  my-auto text-center mr-16 z-10 md:mr-auto md:ml-auto md:mb-6 heading-s md:heading-xs">
          {textArr.map((text, index) => (
            <span
              key={index}
              className={
                index % 2
                  ? "text-blue-accent font-Inter"
                  : "text-blue-darken font-Inter"
              }
            >
              {text}
            </span>
          ))}
        </p>
        <button
          className="btn-parent font-Inter bg-blue-accent h-12 w-48 text-white text-center text-base font-semibold mx-0 my-auto px-8 rounded-2xl whitespace-nowrap z-10 md:mr-auto md:ml-auto hover:bg-blue-hover focus:bg-blue-darken md:mb-16"
          onClick={() =>
            navigate("/contact", { state: { source: "book_demo" } })
          }
        >
          {btnText}
        </button>
      </div>
    </BlueWrapper>
  );
};

export default BluSection;
