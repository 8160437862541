import { css } from "@emotion/react";
import paymentsCurves from "../../../images/payments/payment_curves.svg";
import media from "../../breakpoints";

const EasyUseWrapper = css`
  overflow: hidden;
  img.list-icon {
    width: 21px;
    height: 21px;
    margin: 3px;
  }
  .list-wrapper {
    margin-left: 38px;
    ${media("md")} {
      margin-left: 44px;
    }
  }
  p.list-hint {
    margin-bottom: 28px;
    max-width: 400px;
  }
  .wrapper-parent {
    min-height: 700px;
  }
  .curves-parent {
    background: url(${paymentsCurves}) center no-repeat;
    width: 1624px;
    height: 542px;
    transform: translate(-79%, -100%);
  }
  .image-parent img {
    max-width: 841px;
    width: 100%;
  }
  .title-parent {
    max-width: 486px;
    line-height: 54px;
    ${media("md")} {
      line-height: 38px;
    }
  }
  p.list-hint {
    font-size: 16px;
  }
  .btn-parent {
    max-width: 180px;
  }
  .easy-parent {
    ${media("md")} {
      flex-direction: column-reverse;
    }
  }
`;

export { EasyUseWrapper };
