import React, { FC } from "react";
import ListLike from "../../listLike/listLike";
import { CustomerDegreeWrapper } from "./customerDegree.styles";
import toolsCustomer from "../../../images/tools/tools-bg1.svg";
import check from "../../../images/check.svg";

interface Props {
  //   title: string;
}
const CustomerDegree: FC<Props> = () => {
  const listData = [
    {
      subTitle: "Filter and search users by any customer or device attributes.",
      icon: check,
    },
    {
      subTitle:
        "Network link analysis maps users sharing devices, or IP addresses.",
      icon: check,
    },
    {
      subTitle:
        "Quickly review case summary with customer details, executed and triggered rules, social profile, and geo location.",
      icon: check,
    },
  ];
  return (
    <section className="section-wrapper  relative" css={CustomerDegreeWrapper}>
      <div className="curves-parent absolute top-full left-full"></div>
      <div className="relative wrapper-parent layout-width w-full pt-28">
        <div className="flex flex-row justify-between h-full md:flex-col">
          <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col justify-center mb-32 md:mb-0">
              <p className="title-parent font-Inter text-blue-darken tracking-tight text-5xl font-semibold mb-10 md:text-3xl md:text-left md:mb-7 md:mr-auto">
                360° customer and session visibility
              </p>
              <div className="list-parent flex flex-col justify-center w-full mb-6">
                {listData.map((data, i) => (
                  <ListLike key={i} subTitle={data.subTitle} icon={data.icon} />
                ))}
              </div>
            </div>
          </div>
          <div className="image-parent flex flex-col justify-start mx-auto lg:justify-center md:mb-11">
            <img src={toolsCustomer} alt="360 degree customer" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomerDegree;
