import React, { FC } from "react";
import { Link } from "gatsby";
import arrow from "../../images/dropdown_right_blue.svg";

interface Props {
  id?: string;
  title?: string;
  subTitle: string;
  icon: string;
  link?: {
    text: string;
    url: string;
  };
}
const ListLike: FC<Props> = ({ id, title, subTitle, icon, link }) => {
  return (
    <div id={id} style={{ maxWidth: "470px" }}>
      <div className="font-Inter ralative flex flex-row justify-start bg-transparent w-full">
        <div className="absolute h-full w-14">
          <img className="list-icon" src={icon} alt={title} />
        </div>
        <div className="list-wrapper flex flex-col justify-start ml-14">
          {title ? (
            <p className="font-Inter text-blue-darken text-base font-semibold mb-1">
              {title}
            </p>
          ) : null}
          <p
            className={`list-hint font-Inter text-blue-darken font-normal ${
              title ? "text-sm" : "text-base"
            } ${link ? "mb-3" : "mb-5"}`}
          >
            {subTitle}
          </p>
          {link && (
            <Link
              className="font-Inter relative w-max inline-block mb-12 pr-5 text-sm h-6 font-normal text-blue-accent md:mb-7"
              to={link?.url}
            >
              <img
                className="absolute h-2 w-4 right-0 top-2"
                src={arrow}
                alt="arrow"
              />
              {link?.text}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListLike;
